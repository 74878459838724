{
  "buttons": {
    "today": "今天",
    "agenda": "议程",
    "day": "日",
    "threeDays": "3天",
    "week": "星期",
    "month": "月",
    "showMore": "显示更多"
  },
  "months": {
    "january": "一月",
    "february": "二月",
    "march": "三月",
    "april": "四月",
    "may": "可能",
    "june": "六月",
    "july": "七月",
    "august": "八月",
    "september": "九月",
    "october": "十月",
    "november": "十一月",
    "december": "十二月"
  },
  "weekDays": {
    "Mon": "周一",
    "Tue": "周二",
    "Wed": "周三",
    "Thu": "周四",
    "Fri": "星期五",
    "Sat": "周六",
    "Sun": "星期日"
  },
  "weekShort": "星期"
}
