{
  "buttons": {
    "today": "Hoy",
    "agenda": "Diario",
    "day": "Día",
    "threeDays": "3 Días",
    "week": "Semana",
    "month": "Mes",
    "showMore": "Más"
  },
  "months": {
    "january": "Enero",
    "february": "Febrero",
    "march": "Marzo",
    "april": "Abril",
    "may": "Mayo",
    "june": "Junio",
    "july": "Julio",
    "august": "Agosto",
    "september": "Septiembre",
    "october": "Octubre",
    "november": "Noviembre",
    "december": "Diciembre"
  },
  "weekDays": {
    "Mon": "Lun",
    "Tue": "Mar",
    "Wed": "Mié",
    "Thu": "Jue",
    "Fri": "Vie",
    "Sat": "Sáb",
    "Sun": "Dom"
  },
  "weekShort": "S"
}
