{
  "buttons": {
    "today": "Heute",
    "agenda": "Agenda",
    "day": "Tag",
    "threeDays": "3 Tage",
    "week": "Woche",
    "month": "Monat",
    "showMore": "Mehr"
  },
  "months": {
    "january": "Januar",
    "february": "Februar",
    "march": "März",
    "april": "April",
    "may": "Mai",
    "june": "Juni",
    "july": "Juli",
    "august": "August",
    "september": "September",
    "october": "Oktober",
    "november": "November",
    "december": "Dezember"
  },
  "weekDays": {
    "Mon": "Mo",
    "Tue": "Di",
    "Wed": "Mi",
    "Thu": "Do",
    "Fri": "Fr",
    "Sat": "Sa",
    "Sun": "So"
  },
  "weekShort": "W"
}
