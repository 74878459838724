{
    "buttons": {
      "today": "Сегодня",
      "agenda": "Агенда",
      "day": "День",
      "threeDays": "3 дня",
      "week": "Неделя",
      "month": "Месяц",
      "showMore": "Еще..."
    },
    "months": {
      "january": "Январь",
      "february": "Февраль",
      "march": "Март",
      "april": "Апрель",
      "may": "Май",
      "june": "Июнь",
      "july": "Июль",
      "august": "Август",
      "september": "Сентябрь",
      "october": "Октябрь",
      "november": "Ноябрь",
      "december": "Декабрь"
    },
    "weekDays": {
      "Mon": "Пн",
      "Tue": "Вт",
      "Wed": "Ср",
      "Thu": "Чт",
      "Fri": "Пт",
      "Sat": "Сб",
      "Sun": "Вс"
    },
  "weekShort": "Н"
}
