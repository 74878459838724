{
  "buttons": {
    "today": "Hoje",
    "agenda": "Agenda",
    "day": "Dia",
    "threeDays": "3 Dias",
    "week": "Semana",
    "month": "Mês",
    "showMore": "Mais"
  },
  "months": {
    "january": "Janeiro",
    "february": "Fevereiro",
    "march": "Março",
    "april": "Abril",
    "may": "Maio",
    "june": "Junho",
    "july": "Julho",
    "august": "Agosto",
    "september": "Setembro",
    "october": "Outubro",
    "november": "Novembro",
    "december": "Dezembro"
  },
  "weekDays": {
    "Mon": "Seg",
    "Tue": "Ter",
    "Wed": "Quar",
    "Thu": "Qui",
    "Fri": "Sex",
    "Sat": "Sab",
    "Sun": "Dom"
  },
  "weekShort": "S"
}
