{
  "buttons": {
    "today": "Today",
    "agenda": "Agenda",
    "day": "Day",
    "threeDays": "3 Days",
    "week": "Week",
    "month": "Month",
    "showMore": "More"
  },
  "months": {
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December"
  },
  "weekDays": {
    "Mon": "Mon",
    "Tue": "Tue",
    "Wed": "Wed",
    "Thu": "Thu",
    "Fri": "Fri",
    "Sat": "Sat",
    "Sun": "Sun"
  },
  "weekShort": "W"
}
