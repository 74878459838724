{
  "buttons": {
    "today": "Aujourd",
    "agenda": "Jour",
    "day": "Journée",
    "threeDays": "3 Jours",
    "week": "Semaine",
    "month": "Mois",
    "showMore": "Plus"
  },
  "months": {
    "january": "Janvier",
    "february": "Février",
    "march": "Mars",
    "april": "Avril",
    "may": "Mai",
    "june": "Juin",
    "july": "Juillet",
    "august": "Août",
    "september": "Septembre",
    "october": "Octobre",
    "november": "Novembre",
    "december": "Décembre"
  },
  "weekDays": {
    "Mon": "Lun",
    "Tue": "Mar",
    "Wed": "Mer",
    "Thu": "Jeu",
    "Fri": "Ven",
    "Sat": "Sam",
    "Sun": "Dim"
  },
  "weekShort": "S"
}
